import type { SerializeFrom } from "@remix-run/node"
import { redirect, useRouteLoaderData } from "@remix-run/react"
import type { UserRole } from "db"

import type { loader as rootLoader } from "@/app/root"

function isUser(
	user: unknown,
): user is SerializeFrom<typeof rootLoader>[`user`] {
	return (
		typeof user === `object` &&
		user !== null &&
		`id` in user &&
		typeof user.id === `string`
	)
}

export function useOptionalUser() {
	const data = useRouteLoaderData<typeof rootLoader>(`root`)
	if (!data || !isUser(data.user)) {
		return undefined
	}
	return data.user
}

export function useUser() {
	const maybeUser = useOptionalUser()
	if (!maybeUser) {
		throw redirect(`/`)
	}
	return maybeUser
}

type Action =
	| `create`
	| `delete`
	| `disable`
	| `enable`
	| `list`
	| `read`
	| `update`
type Entity =
	| `admin-menu`
	| `app-version`
	| `artifacts`
	| `assessment-responses`
	| `assistant`
	| `dimensions`
	| `organization-filter`
	| `organizations`
	| `products`
	| `switch-organization`
	| `users`
export type PermissionString = `${Action}:${Entity}`
export function parsePermissionString(permissionString: PermissionString) {
	const [action, entity] = permissionString.split(`:`) as [Action, Entity]
	return {
		action,
		entity,
	}
}

/*
 * @deprecated use `Authorization.hasPermission`
 * */
export function userHasPermission(
	user: Pick<ReturnType<typeof useUser>, `rbac`> | null,
	permission: PermissionString,
) {
	if (!user) return false
	const { action, entity } = parsePermissionString(permission)

	if (!user?.rbac.permissions) return false
	return user.rbac.permissions.some((p) => p === `${action}:${entity}`)
}

/*
 * @deprecated use `Authorization.hasRole`
 * */
export function userHasRole(
	user: Pick<ReturnType<typeof useUser>, `rbac`> | null,
	role: UserRole,
) {
	if (!user) return false

	if (!user.rbac.roles) return false
	return user?.rbac.roles.some((r) => r === role)
}
